import React, { ChangeEvent, FunctionComponent, ReactNode } from 'react';
import { InjectedIntl } from 'react-intl';
import cx from 'classnames';
import { Fieldset } from '@nbc-design/fieldset';
import { Radio } from '@nbc-design/radio';
import { Text } from '@nbc-design/text';
import { FormComponentProps, OptionTooltip, OptionsType } from '../types';
import { getLabelText, getErrorMessage, isInvalidOnChange, getSchemaOptions, getOptionsReadOnlyLabel } from '../utils';
import { getTextFactory } from 'utils/TextUtils';
import AnalyticsHelper from 'services/analytics/AnalyticsHelper';

import './style.scss';
import { Row } from 'react-flexbox-grid';

export type ProductFormRadioSelectProps = FormComponentProps & {
  intl: InjectedIntl;
  disabledOptions: string[];
  inline?: boolean;
  tooltip?: React.ReactNode;
  optionTooltips?: OptionTooltip[];
};

const ProductFormRadioSelect: FunctionComponent<ProductFormRadioSelectProps> = (props: ProductFormRadioSelectProps) => {
  const {
    optionTooltips,
    tooltip,
    helpText = '',
    input,
    isReadOnly,
    intl,
    disabledOptions = [],
    inline,
    className = '',
    meta,
  } = props;

  const invalid = isInvalidOnChange(props) || (meta.touched && !!meta.error);
  const options: OptionsType[] = getSchemaOptions(props);

  const readOnlyLabel = getOptionsReadOnlyLabel(options, input.value);

  const label = getLabelText(props);
  const getAccessibilityText = getTextFactory(intl, 'accessibility');
  const onShowTooltip = AnalyticsHelper.handleShowTooltip(input.name);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (input.onChange) {
      input.onChange(event);
    }
  };

  const getRadioComponent = (option: OptionsType): ReactNode => {
    return (
      <Radio
        id={`radio-${input.name}-${option.value}`}
        key={option.value}
        label={option.label}
        value={option.value}
        name={input.name}
        checked={input.value && input.value === option.value}
        disabled={option.readOnly || disabledOptions.indexOf(option.value) !== -1}
        onChange={handleChange}
      />
    );
  };
  const radio = (option: OptionsType, optionToolip?: ReactNode): ReactNode => {
    return !optionToolip ? (
      getRadioComponent(option)
    ) : (
      <Row className="radio-tooltip-inline" key={`row-${option.value}`}>
        {getRadioComponent(option)}
        {optionToolip}
      </Row>
    );
  };

  const radios = options.map(
    (option: OptionsType): ReactNode => {
      const tooltipElement: ReactNode | undefined = optionTooltips?.find((value) => value.optionValue === option.value)
        ?.tooltip;
      return radio(option, tooltipElement);
    },
  );

  const content = inline ? <div className="radio-inline">{radios}</div> : radios;

  return (
    <Fieldset
      legend={label}
      tooltip={{ content: tooltip, ariaLabel: getAccessibilityText('information'), onShowTooltip }}
      validate={{ hasError: invalid, errorMsg: getErrorMessage(props) || '' }}
      description={{ text: helpText, id: 'dscFieldset__description' }}
      className={cx(className, { inline })}
    >
      {isReadOnly ? <Text type={'p'}>{readOnlyLabel}</Text> : content}
    </Fieldset>
  );
};

export default ProductFormRadioSelect;
