import React, { ChangeEvent, FunctionComponent, ReactNode, useEffect } from 'react';
import { InjectedIntl } from 'react-intl';

import { getTextFactory } from 'utils/TextUtils';
import { Heading } from '@nbc-design/heading';
import { Divider } from '@nbc-design/divider';
import {
  ProductFormAmountInput,
  ProductFormInput,
  ProductFormRadioSelect,
  ProductFormSelect,
} from 'components/productFormFields';
import { createErrorFormatter } from '../../../utils/productFormUtils';
import { Col, Row } from 'react-flexbox-grid';
import { ProductFormFieldChangeHandler, RefData, Schema } from '../../../types/interfaces';
import { get, isNil } from 'lodash';
import { ANNUALLY, BIANNUALLY, MONTHLY, QUARTERLY, accountPaymentTypesConfig } from '../constants';
import { AccountPaymentTypesConfig, PaymentDetail, PaymentTypeCd, TransferModeCd } from '../types';
import BaoTooltip from 'components/BaoTooltip/BaoTooltip';
import config from '../config';
import { filterPaymentTypes, initializeSplitPercentage, syncPaymentFields } from '../utils';
import { ACCOUNT_TYPE, ACCOUNTS_WITH_PROVINCE } from 'containers/AccountsStep1/constants';
import { CURRENCIES, PROVINCES } from 'utils/constants';
import { CategoryTaxes } from '@nbc-design/icons/lib/web/CategoryTaxes';
import '../style.scss';
import { OptionTooltip } from 'components/productFormFields/types';

export interface AccountPaymentDetailsProps {
  intl: InjectedIntl;
  index: number;
  schema: Schema;
  change: ProductFormFieldChangeHandler;
  paymentDetail: PaymentDetail;
  disableEFT: boolean;
  individual: {
    age: number;
    birthday: string;
  };
  payments: PaymentDetail[];
}

const AccountPaymentDetails: FunctionComponent<AccountPaymentDetailsProps> = ({
  intl,
  schema,
  index,
  paymentDetail,
  disableEFT,
  individual,
  payments,
  change,
}) => {
  const getText = getTextFactory(intl, 'paymentInformation');
  const getGlobalText = getTextFactory(intl, 'global');

  const formatErrorMessage = (prefix?: string, fieldName?: string) => createErrorFormatter(intl, prefix, fieldName);

  const paymentTypeCdRef: RefData[] = get(schema, 'definitions.PaymentDetail.properties.paymentTypeCd.refData', []);

  const RRIFUSDIndex = payments.findIndex(
    (payment) => payment.accountType.code === ACCOUNT_TYPE.RRIF && payment.currency.code === CURRENCIES.USD,
  );
  const RRIFCADIndex = payments.findIndex(
    (payment) => payment.accountType.code === ACCOUNT_TYPE.RRIF && payment.currency.code === CURRENCIES.CAD,
  );

  const hasRRIFUSD: boolean = RRIFUSDIndex !== -1;
  const isRRIFCAD: boolean = RRIFCADIndex === index;
  const isRRIFUSD: boolean = RRIFUSDIndex === index;
  const displayPercent = (isRRIFCAD || isRRIFUSD) && hasRRIFUSD;
  const shouldUpdateRRIFUSD = isRRIFCAD && hasRRIFUSD;

  const hasClientAgeRestriction =
    paymentDetail?.accountType?.code === ACCOUNT_TYPE.LIF &&
    paymentDetail?.province?.code === PROVINCES.QC &&
    individual.age > 54;

  const paymentOptions = [PaymentTypeCd.MINIMUM, PaymentTypeCd.FIXED, PaymentTypeCd.MAXIMUM, PaymentTypeCd.TEMPORARY];

  const filteredPaymentTypesCdRef: RefData[] = filterPaymentTypes(
    paymentTypeCdRef,
    accountPaymentTypesConfig.find(
      (value: AccountPaymentTypesConfig) =>
        value.accountType === (paymentDetail?.accountType?.code as ACCOUNT_TYPE) &&
        (value?.currency?.includes(paymentDetail?.currency.code as CURRENCIES) ?? true) &&
        (value?.province?.includes(paymentDetail?.province?.code as PROVINCES) ?? true),
    )?.paymentTypes ?? paymentOptions,
  );

  hasClientAgeRestriction && filteredPaymentTypesCdRef.pop();

  const referenceAgeTypeCdRef: RefData[] = get(
    schema,
    'definitions.PaymentDetail.properties.referenceAgeTypeCd.refData',
    [],
  );

  const transferModeCdCdRef: RefData[] = get(schema, 'definitions.PaymentDetail.properties.transferModeCd.refData', []);

  const freqCdRef: RefData[] = get(schema, 'definitions.PaymentDetail.properties.frequencyCd.refData', []);

  const dayOfMonthRef: RefData[] = get(schema, 'definitions.PaymentDetail.properties.dayOfMonth.refData', []);

  const monthsRef: RefData[] = get(schema, 'definitions.PaymentDetail.properties.startMonth.refData', []);
  const getMonths = () => {
    let result: RefData[] = [];
    if (paymentDetail && paymentDetail.frequencyCd) {
      switch (paymentDetail.frequencyCd) {
        case 'M':
          result = monthsRef.filter((month) => MONTHLY.includes(month.valueDomainCode));
          break;
        case 'T':
          result = monthsRef.filter((month) => QUARTERLY.includes(month.valueDomainCode));
          break;
        case 'S':
          result = monthsRef.filter((month) => BIANNUALLY.includes(month.valueDomainCode));
          break;
        case 'A':
          result = monthsRef.filter((month) => ANNUALLY.includes(month.valueDomainCode));
          break;
        default:
          result = [];
      }
      return result;
    }
  };

  const isDisabled = (): boolean => {
    return !(paymentDetail && paymentDetail.frequencyCd);
  };

  const isHiddenAmount = !(paymentDetail && paymentDetail.paymentTypeCd && paymentDetail.paymentTypeCd === '02');
  const isHiddenTransfer = !(paymentDetail && paymentDetail.transferModeCd && paymentDetail.transferModeCd === 'CDBN');

  useEffect(() => {
    initializeSplitPercentage('splitPercentage', payments, change, RRIFCADIndex, RRIFUSDIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldUpdateRRIFUSD) {
      syncPaymentFields(
        ['frequencyCd', 'startMonth', 'dayOfMonth', 'paymentTypeCd', 'amount', 'referenceAgeTypeCd'],
        payments,
        change,
        RRIFCADIndex,
        RRIFUSDIndex,
      );
    }
  }, [RRIFCADIndex, RRIFUSDIndex, change, payments, shouldUpdateRRIFUSD]);

  const handleOnTransferModeChange = () => {
    // Reset select field
    change(`payments.[${index}].transferAccountNumber`, null);
  };

  const handleOnPaymentTypeChange = () => {
    // Reset select field
    change(`payments.[${index}].amount`, null);
  };

  const handleOnFrequencyChange = () => {
    // Reset select field
    change(`payments.[${index}].startMonth`, null);
  };

  const handleUpdatePercentage = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const valueAsNumber = Number(value);
    const indexToUpdate: number | null = isRRIFCAD ? RRIFUSDIndex : RRIFCADIndex;
    const clampedValue: number = Math.max(0, Math.min(100, Number(value)));

    if (hasRRIFUSD && !isNaN(valueAsNumber)) {
      change(`payments.[${index}].splitPercentage`, clampedValue);

      if (!isNil(indexToUpdate)) {
        const computedPercent = 100 - clampedValue;
        change(`payments.[${indexToUpdate}].splitPercentage`, computedPercent);
      }
    }
  };

  const displayProvince: boolean =
    ACCOUNTS_WITH_PROVINCE.includes(paymentDetail?.accountType?.code as ACCOUNT_TYPE) &&
    !isNil(paymentDetail?.province);

  const viagerAccount: boolean =
    paymentDetail &&
    paymentDetail.accountType &&
    (paymentDetail.accountType.code === 'LIF' ||
      paymentDetail.accountType.code === 'RLIF' ||
      paymentDetail.accountType.code === 'FLIF');

  const viagerAmountTooltip = getText('amountViagerInfo');
  const retirementAmountTooltip = getText('amountRetirementInfo');
  const desiredAmountTooltip = viagerAccount ? viagerAmountTooltip : retirementAmountTooltip;

  const specificTooltip = (name: string, content: string): ReactNode => {
    return (
      <BaoTooltip
        name={name}
        key={name}
        stepId={config.ID!}
        content={content}
        position="left"
        className="payment-information-tooltip"
      />
    );
  };

  const maximumAmountTooltip: OptionTooltip = {
    optionValue: PaymentTypeCd.MAXIMUM,
    tooltip: specificTooltip(`payments.[${index}].paymentTypeCd`, getText('amountMaximumInfo')),
  };

  const paymentAmountTooltips: OptionTooltip[] = [
    // Refer to https://wiki.bnc.ca/pages/viewpage.action?pageId=193495603
    {
      optionValue: PaymentTypeCd.FIXED,
      tooltip: specificTooltip(`payments.[${index}].paymentTypeCd`, desiredAmountTooltip),
    },
    {
      optionValue: PaymentTypeCd.TEMPORARY,
      tooltip: specificTooltip(`payments.[${index}].paymentTypeCd`, getText('amountTemporaryInfo')),
    },
  ];

  hasClientAgeRestriction && paymentAmountTooltips.splice(1, 0, maximumAmountTooltip);

  const getTooltipOptions = (): OptionTooltip[] | undefined => [
    {
      optionValue: TransferModeCd.EFT,
      tooltip: specificTooltip(
        `payments.[${index}].transferModeCd`,
        disableEFT
          ? `${getText('transferInfo.disableEFT1')} ${paymentDetail.currency[intl.locale]} ${getText(
              'transferInfo.disableEFT2',
            )}`
          : getText('transferInfo'),
      ),
    },
  ];

  const getTransferModeDisabledOptions = (): string[] | undefined => (disableEFT ? ['EFT'] : []);

  return (
    <div data-test={`account-${index}`}>
      <Heading type="h3" className="account-name">
        {`${paymentDetail?.accountType?.[intl.locale]}${
          displayProvince ? ' ' + paymentDetail?.province?.[intl.locale] : ''
        } ${paymentDetail?.currency?.code}`}
      </Heading>
      {isRRIFUSD === false && (
        <>
          <div className="frequency-section">
            <Heading type="h4" className="account-info-header">
              {getText('frequencyHeader')}
            </Heading>
            <Divider appearance="normal" size="small" />
            <Row>
              <Col xs={12} md={6} data-test="field_frequencyCd">
                <ProductFormSelect
                  name={`payments.[${index}].frequencyCd`}
                  label={getText('frequency')}
                  formatErrorMessage={formatErrorMessage()}
                  placeholder={getGlobalText('select')}
                  refData={freqCdRef}
                  onChange={handleOnFrequencyChange}
                />
              </Col>
              <Col xs={12} md={6} data-test="field_startMonth">
                <ProductFormSelect
                  name={`payments.[${index}].startMonth`}
                  label={getText('startDate')}
                  formatErrorMessage={formatErrorMessage()}
                  placeholder={getGlobalText('select')}
                  refData={getMonths()}
                  disabled={isDisabled()}
                />
              </Col>
              <Col xs={12} md={6} data-test="field_dayOfMonth">
                <ProductFormSelect
                  name={`payments.[${index}].dayOfMonth`}
                  label={getText('paymentDate')}
                  formatErrorMessage={formatErrorMessage()}
                  refData={dayOfMonthRef}
                  placeholder={getGlobalText('select')}
                />
              </Col>
            </Row>
          </div>
          <Heading type="h4" className="account-info-header calculation-header">
            {getText('calculationHeader')}
          </Heading>
          <Divider appearance="normal" size="small" />
          <ProductFormRadioSelect
            name={`payments.[${index}].paymentTypeCd`}
            label={getText('desiredAmount')}
            formatErrorMessage={formatErrorMessage()}
            refData={filteredPaymentTypesCdRef}
            onChange={handleOnPaymentTypeChange}
            optionTooltips={paymentAmountTooltips}
          />
          {!isHiddenAmount && (
            <Row>
              <Col xs={12} md={6} data-test="field_amount" className={'field-input'}>
                <ProductFormAmountInput
                  name={`payments.[${index}].amount`}
                  fractionDigit={2}
                  placeholder={getText('amountInput.placeholder')}
                  formatErrorMessage={formatErrorMessage()}
                />
              </Col>
            </Row>
          )}
          <ProductFormRadioSelect
            name={`payments.[${index}].referenceAgeTypeCd`}
            label={getText('minimumDeterminedBy')}
            formatErrorMessage={formatErrorMessage()}
            refData={referenceAgeTypeCdRef}
          />
        </>
      )}

      <Heading type="h4" className="account-info-header receipt-header">
        {getText('receiptHeader')}
      </Heading>
      <Divider appearance="normal" size="small" />
      {displayPercent ? (
        <ProductFormInput
          name={`payments.[${index}].splitPercentage`}
          inputProps={{
            length: 'xSmall',
            icon: <CategoryTaxes size="xsmall" title="category-taxes" />,
            min: 0,
            max: 100,
            type: 'number',
          }}
          type="number"
          className="splitPercentage-input mc-mb-medium"
          label={getText(`splitPercentage`)}
          introduction={getText(`splitPercentage.introduction`)}
          formatErrorMessage={formatErrorMessage()}
          tooltip={getText('splitPercentage.tooltip')}
          handleOnChange={handleUpdatePercentage}
          handleOnBlur={handleUpdatePercentage}
        />
      ) : undefined}
      <ProductFormRadioSelect
        name={`payments.[${index}].transferModeCd`}
        label={getText('receiveBy')}
        formatErrorMessage={formatErrorMessage()}
        disabledOptions={getTransferModeDisabledOptions()}
        refData={transferModeCdCdRef}
        onChange={handleOnTransferModeChange}
        optionTooltips={getTooltipOptions()}
      />
      {!isHiddenTransfer && (
        <Row>
          <Col xs={12} md={6} data-test="field_transferAccountNumber" className={'field-input'}>
            <ProductFormInput
              maxLength={7}
              name={`payments.[${index}].transferAccountNumber`}
              formatErrorMessage={formatErrorMessage()}
              placeholder={getGlobalText('transferAccountNumber')}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default AccountPaymentDetails;
